import vaults from '../constants/vaults'
import getStrategyByAddress from '../utils/getStrategyByAddress'
import StrategyPage from './strategies/[address]'

export default function Home ({ defaultStrategy }) {
  return (
    <StrategyPage strategy={defaultStrategy} />
  )
}

export async function getServerSideProps () {
  const vault = vaults.at(0)
  const strategy = await getStrategyByAddress(vault.address)

  return {
    props: {
      defaultStrategy: strategy
    }
  }
}
